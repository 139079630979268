.otp-input-container {
  display: flex;
  align-items: center;
}

.otp-input-item {
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  margin: 0 0.2rem;
  padding: 0;
  font-size: 1.5rem;
  font-weight: bold;
  border: 1px solid var(--neutral-200);
  border-radius: .5rem;
  background: transparent;
  color: var(--text-main);
}

.otp-input-item:focus {
  border-color: transparent;
  outline: 2px solid var(--primary-color);
  color: var(--text-main);
}

.otp-input-item:disabled {
  border: 1px solid var(--neutral-200);
  background: transparent;
  color: var(--text-main);
  opacity: 1;
}

.otp-divider {
  margin: 0 0.5rem;
  font-size: 1.5rem;
}
