:root {
  --blue-50: #f0f9ff;
  --blue-100: #e0f2fe;
  --blue-200: #bae6fd;
  --blue-300: #7dd3fc;
  --blue-400: #38bdf8;
  --blue-500: #0ea5e9;
  --blue-600: #0284c7;
  --blue-700: #0369a1;
  --blue-800: #075985;
  --blue-900: #0c4a6e;
  --blue-950: #082f49;
  
  --teal-50: #f0fdfa;
  --teal-100: #ccfbf1;
  --teal-200: #99f6e4;
  --teal-300: #5eead4;
  --teal-400: #2dd4bf;
  --teal-500: #14b8a6;
  --teal-600: #0d9488;
  --teal-700: #0f766e;
  --teal-800: #115e59;
  --teal-900: #134e4a;
  --teal-950: #042f2e;

  --green-50: #ecfdf5;
  --green-100: #d1fae5;
  --green-200: #a7f3d0;
  --green-300: #6ee7b7;
  --green-400: #34d399;
  --green-500: #10b981;
  --green-600: #059669;
  --green-700: #047857;
  --green-800: #065f46;
  --green-900: #064e3b;
  --green-950: #022c22;
  
  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;
  
  --grey-50: #fafafa;
  --grey-100: #f4f4f5;
  --grey-200: #e4e4e7;
  --grey-300: #d4d4d8;
  --grey-400: #a1a1aa;
  --grey-500: #71717a;
  --grey-600: #52525b;
  --grey-700: #3f3f46;
  --grey-800: #27272a;
  --grey-900: #18181b;
  --grey-950: #09090b;
}

[data-theme="light"] {
  --bg-main: #ffffff;
  --bg-secondary: var(--grey-50);
  --text-main: #000000;
  --text-secondary: var(--grey-800);
  --text-tertiary: var(--grey-600);
  --primary-color: var(--blue-500);
  --primary-color-light: var(--blue-400);
  --primary-color-dark: var(--blue-600);
  --secondary-color: var(--teal-500);
  --secondary-color-light: var(--teal-400);
  --secondary-color-dark: var(--teal-600);
  --error-bg: var(--red-100);
  --error-text: var(--red-700);
  --success-bg: var(--green-100);
  --success-text: var(--green-700);
  --neutral-50: var(--grey-50);
  --neutral-100: var(--grey-100);
  --neutral-200: var(--grey-200);
  --neutral-300: var(--grey-300);
  --neutral-400: var(--grey-400);
  --neutral-500: var(--grey-500);
  --neutral-600: var(--grey-600);
  --neutral-700: var(--grey-700);
  --neutral-800: var(--grey-800);
  --neutral-900: var(--grey-900);
  --neutral-950: var(--grey-950);
}
[data-theme="dark"] {
  --bg-main: var(--grey-950);
  --bg-secondary: var(--grey-900);
  --text-main: #ffffff;
  --text-secondary: var(--grey-100);
  --text-tertiary: var(--grey-200);
  --primary-color: var(--teal-500);
  --primary-color-light: var(--teal-400);
  --primary-color-dark: var(--teal-600);
  --secondary-color: var(--blue-500);
  --secondary-color-light: var(--blue-400);
  --secondary-color-dark: var(--blue-600);
  --error-bg: var(--red-900);
  --error-text: var(--red-200);
  --success-bg: var(--green-900);
  --success-text: var(--green-200);
  --neutral-50: var(--grey-950);
  --neutral-100: var(--grey-900);
  --neutral-200: var(--grey-800);
  --neutral-300: var(--grey-700);
  --neutral-400: var(--grey-600);
  --neutral-500: var(--grey-500);
  --neutral-600: var(--grey-400);
  --neutral-700: var(--grey-300);
  --neutral-800: var(--grey-200);
  --neutral-900: var(--grey-100);
  --neutral-950: var(--grey-50);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--primary-color);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-main);
  margin: 1rem 0;
}

p {
  font-size: .9rem;
  color: var(--text-secondary);
}

div {
  color: var(--text-secondary);
}

.page {
  display: flex;
  min-height: 100dvh;
  min-width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  background-color: var(--bg-main);
}

.card {
  position: relative;
  display: flex;
  background-color: #ffffff10;
  backdrop-filter: blur(30px);
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 768px) {
  .card {
    background: transparent;
    box-shadow: none;
  }
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.copyright {
  position: absolute;
  bottom: 0;
  font-size: .85rem;
  color: var(--neutral-400);
} 

.material-symbols-outlined {
  user-select: none;
}

.material-symbols-outlined.clickable {
  cursor: pointer;
}

.text-button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: .5rem;
  cursor: pointer;
}

.twocol {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.leftcol {
  width: 50%;
}

.rightcol {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .twocol {
    flex-direction: column;
  }
  .leftcol {
    width: 100%;
  }
  .rightcol {
    width: 100%;
  }
}

.course-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  width: fit-content;
  min-width: 20rem;
  padding: 1.5rem;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  gap: .75rem;
}
.course-card > * {
  margin: 0;
}

.vertical-group {
  display: flex;
  flex-direction: column;
}

.inline-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inline-group > * {
  margin: 0;
}

.message {
  padding: 1rem;
  border-radius: .5rem;
  margin-top: 1rem;
  max-width: 21rem;
}
.message.error {
  background-color: var(--error-bg);
  color: var(--error-text);
  
}
.message.success {
  background-color: var(--success-bg);
  color: var(--success-text);
}