.timepicker-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
  }
.timepicker-wrapper:focus {
    outline: none;
}
  
  .timepicker-field {
      height: 2rem;
      width: 17rem;
      border: 1px solid var(--neutral-200);
      border-radius: .5rem;
      background: transparent;
      color: var(--text-main);
      position: relative;
      cursor: pointer;
      user-select: none;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      transition: border-color 0.15s ease-out, outline 0.15s ease-out;
  }
  
  .timepicker-field.active {
      border-color: transparent;
      outline: 2px solid var(--primary-color);
  }
  
  .placeholder-text {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      pointer-events: none;
  }
  
  .label-text {
      font-size: .85rem;
      background-color: transparent;
      color: var(--text-main);
      transform: translate(0);
      transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
  }
  
  .timepicker-field, .placeholder-text {
      font-size: .85rem;
      padding: 0 1rem;
  }
  
  .timepicker-field.active + .placeholder-text .label-text {
      font-size: .75rem;
      padding-inline: .2rem;
      color: var(--primary-color);
      transform: translate(0, -130%);
      background-color: var(--above-bg-db);
  }
  
  .popover {
      position: absolute;
      display: flex;
      justify-content: space-around;
      width: 16rem;
      background-color: var(--bg-main);
      padding: 1rem;
      border-radius: .5rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;
  }

  .time-input-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

.time-input {
    width: 3rem;
    text-align: center;
    padding: 0.5rem;
    border: 1px solid var(--neutral-200);
    border-radius: 0.25rem;
    font-size: 1rem;
    margin: 0 0.25rem;
    transition: border-color 0.3s;
    background: none;
    color: var(--text-main);
}

.time-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.period-selector {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.period-option {
    padding: 0.5rem 1rem;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 0.25rem;
    background-color: transparent;
    transition: background-color 0.3s, color 0.3s, font-weight 0.3s;
    font-size: 1rem;
    color: var(--neutral-400);
}

.period-option.selected {
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
}

.period-option:focus {
    outline: none;
    background-color: var(--secondary-color);
    color: white;
}