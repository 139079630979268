/* PhoneInput.module.css */

.phone-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 19rem; /* Keep total width at 17rem */
    border: 1px solid var(--neutral-200);
    border-radius: .5rem;
  }
  .phone-input-wrapper:focus-within {
    outline: 2px solid var(--primary-color);
  }
  
  .phone-input-dropdown {
    height: 2rem;
    width: 3rem;
    background: transparent;
    color: var(--text-main);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 1rem;
  }
  
  .phone-input-dropdown-header {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .phone-input-dropdown-arrow {
    margin-left: 0;
    transition: transform 0.3s ease;
    font-size: 1rem;
    user-select: none;
  }
  
  .phone-input-dropdown-arrow.open {
    transform: rotate(180deg);
  }
  
  .phone-input-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: .5rem;
    background: var(--above-bg);
    z-index: 1000;
    max-height: 17rem;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .phone-input-dropdown-options {
    max-height: 17rem;
    overflow-y: auto;
  }
  
  .phone-input-dropdown-option {
    padding: .75rem 1rem;
    cursor: pointer;
    font-size: .85rem;
    border-top: 1px solid var(--neutral-200);
    color: var(--text-main);
    background: var(--above-bg);
  }
  
  .phone-input-dropdown-option:first-child {
    border-top: none;
  }
  
  .phone-input-dropdown-option:hover {
    background-color: var(--neutral-200);
  }
  
  .phone-input-dropdown-option.highlighted {
    background-color: var(--neutral-200);
  }
  
  .phone-input-dropdown-search {
    background-color: transparent;
    color: var(--neutral-600);
    padding: .5rem 1rem;
  }
  
  .phone-input-search-input {
    background: transparent;
    width: calc(100% - 2rem);
    border: none;
    outline: none;
    color: var(--neutral-600);
    padding: .5rem 0;
  }
  
  .phone-input-field {
    height: 2rem;
    width: 100%;
    border: none;
    background: transparent;
    color: var(--text-main);
    padding-right: 1rem;
    padding-left: .5rem;
  }
  
  .phone-placeholder-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3rem; /* Adjust position to accommodate country code dropdown */
    right: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    pointer-events: none;
  }
  
  .phone-label-text {
    font-size: .85rem;
    background-color: transparent;
    color: var(--text-main);
    transform: translate(0);
    transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
  }
  
  input, .phone-placeholder-text {
    font-size: .85rem;
    padding: 0 1rem;
  }
  
  .phone-input-field:focus {
    border-color: transparent;
    outline: none;
    color: var(--text-main);
  }
  
 /* Transition the label when the input is focused */
.phone-input-field:focus + .phone-placeholder-text .phone-label-text {
    font-size: .75rem;
    padding-inline: .2rem;
    color: var(--primary-color);
    transform: translate(-3rem, -130%);
    background-color: var(--above-bg);
  }
  
  /* Transition the label when the input has a value */
  :not(input[value=""]) + .phone-placeholder-text .phone-label-text {
    font-size: .75rem;
    padding-inline: .2rem;
    color: var(--text-tertiary);
    transform: translate(-3rem, -130%);
    background-color: var(--above-bg);
  }

  /* New style to color the label text when the dropdown is focused and the input has a value */
.phone-input-wrapper:focus-within input[value]:not([value=""]) + .phone-placeholder-text .phone-label-text {
    color: var(--primary-color);
  }
  
  @media only screen and (max-width: 768px) {
    input:focus + .phone-placeholder-text .phone-label-text,
    :not(input[value=""]) + .phone-placeholder-text .phone-label-text {
      background-color: var(--bg-main);
    }
  }
  
   /* Scrollbar Styling */
   .phone-input-dropdown-options::-webkit-scrollbar {
    width: 4px;
    cursor: auto;
  }
  
  .phone-input-dropdown-options::-webkit-scrollbar-thumb {
    background-color: var(--text-tertiary);
    border-radius: 10px;
    cursor: auto;
  }
  
  .phone-input-dropdown-options::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-tertiary);
  }
  
  .phone-input-dropdown-options::-webkit-scrollbar-track {
    background: transparent;
  }

  .phone-input-dropdown-search {
    background-color: transparent;
    color: var(--neutral-600);
  }

  .flag-icon {
    width: 1rem;
    height: auto;
    margin-right: .5rem;
  }