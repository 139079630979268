.button {
    padding: .5rem 1.5rem;
    border-radius: .5rem;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    user-select: none;
}

.button-primary {
    padding: calc(.5rem + 2px) calc(1.5rem + 2px);
    background: linear-gradient(to bottom right, var(--blue-500), var(--teal-500));
    color: white;
}
.button-primary:hover {
    background: linear-gradient(to bottom right, var(--blue-400), var(--teal-400));
}
.button-primary:active {
    background: linear-gradient(to bottom right, var(--blue-300), var(--teal-300));
}

.button-secondary { 
    background: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.button-secondary:hover {
    background: var(--primary-color);
    color: white;
    /* box-shadow: 0 4px 8px #8F41C570; */
}
.button-secondary:active {
    background: var(--primary-color-light);
    border: 1px solid var(--primary-color-light);
    color: white;
}

.button-neutral {
    background: transparent;
    color: var(--text-main);
    border: 1px solid var(--neutral-200);
}
.button-neutral:hover {
    background: var(--neutral-200);
    /* box-shadow: 0 4px 8px #00000050; */
}
.button-neutral:active {
    background: var(--neutral-300);
    border: 1px solid var(--neutral-300);
}

.button-success {
    background: transparent;
    color: #0eba8c;
    border: 1px solid #0eba8c;
}
.button-success:hover {
    background: #0eba8c;
    color: white;
    box-shadow: 0 4px 8px #0eba8c70;
}
.button-success:active {
    background: #26cba2;
    border: 1px solid #26cba2;
    color: white;
}

.button-danger {
    background: transparent;
    color: #e0395a;
    border: 1px solid #e0395a;
}
.button-danger:hover {
    background: #e0395a;
    color: white;
    box-shadow: 0 4px 8px #e0395a70;
}
.button-danger:active {
    background: #fa5e7d;
    border: 1px solid #fa5e7d;
    color: white;
}