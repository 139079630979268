.navbar {
    display: flex;
    padding: 1rem;
    height: 3rem;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 1rem 1rem;
    background: var(--bg-main);
    color: var(--text-main);
}

.leftSide {
    display: flex;
    align-items: center;
}

.nav-logo {
    height: 2rem;
}

.nav-items {
    display: flex;
    list-style-type: none;
    gap: 1rem;
    font-size: .9rem;
}
.nav-items > li {
    cursor: pointer;
    padding: .25rem;
}
.nav-items > li:hover {
    background: var(--neutral-200);
    border-radius: .25rem;
}

.nav-items > li.active {
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    font-weight: bold;
}

.mobile-navbar {
    display: none;
}
.mobile-dropdown-menu {
    display: none;
}

@media screen and (max-width: 768px) {
    .rightSide {
        display: none;
    }
    .nav-items {
        display: none;
    }
    .mobile-navbar {
        display: flex;
        user-select: none;
        align-items: center;
    }

    .mobile-dropdown-menu {
        display: block;
        position: absolute;
        top: 5rem; /* Position it just below the navbar */
        right: 0;
        width: 75%; /* 75% of the screen width */
        background: var(--bg-main);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        border-radius: 1rem;
        font-size: .9rem;
        padding: 1rem;
        z-index: 1000;
    }

    .mobile-nav-items {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .mobile-nav-items > li {
        cursor: pointer;
        padding: .5rem .25rem;
        display: flex;
        align-items: center;
        gap: .5rem;
        border-radius: .4rem;
    }

    .mobile-nav-items > li:hover {
        background: var(--neutral-200);
    }
    .mobile-nav-items > li.active {
        color: var(--primary-color);
        font-weight: bold;
    }
    .mobile-nav-items > li .material-symbols-outlined {
        font-size: 20px;
    }
}
