.tooltipWrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: default;
}

.tooltipContent {
    position: absolute;
    background-color: var(--bg-main);
    color: var(--text-main);
    padding: .5rem;
    border-radius: 0.25rem;
    white-space: nowrap;
    font-size: 0.9rem;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

/* Tooltip directions */
.above {
    bottom: 100%; /* Remove the small space */
    left: 50%;
    transform: translateX(-50%);
}

.below {
    top: 100%; /* Remove the small space */
    left: 50%;
    transform: translateX(-50%);
}

.left {
    right: 100%; /* Remove the small space */
    top: 50%;
    transform: translateY(-50%);
}

.right {
    left: 100%; /* Remove the small space */
    top: 50%;
    transform: translateY(-50%);
}

.tooltipWrapper:hover .tooltipContent,
.tooltipWrapper .tooltipContent:hover {
    opacity: 1;
    visibility: visible;
}
