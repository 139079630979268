.inputWrapper {
    width: 17rem;
    height: 2rem;
    border: 1px solid var(--neutral-200);
    border-radius: .5rem;
    background: transparent;
    color: var(--text-main);
    position: relative;
    display: flex;
    align-items: center;
    padding:  0 1rem;
}

.inputField {
    display: none;
}

.placeholderText {
    display: flex;
    align-items: center;
    font-size: .9rem;
    gap: .5rem;
    width: 100%;
    height: 100%;
    cursor: pointer;
}