.dashboard-page {
    height: 100vh;
    width: 100vw;
    background: var(--bg-secondary);
    margin: 0;
    padding: 0;
}
.main {
    display: flex;
    flex-wrap: wrap;
    max-width: 1406px;
    margin: 0 auto;
    padding: 2rem;
}