.datepicker-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
  }
.datepicker-wrapper:focus {
    outline: none;
}
  
  .datepicker-field {
      height: 2rem;
      width: 17rem;
      border: 1px solid var(--neutral-200);
      border-radius: .5rem;
      background: transparent;
      color: var(--text-main);
      position: relative;
      cursor: pointer;
      user-select: none;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      transition: border-color 0.15s ease-out, outline 0.15s ease-out;
  }
  
  .datepicker-field.active {
      border-color: transparent;
      outline: 2px solid var(--primary-color);
  }
  
  .placeholder-text {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      pointer-events: none;
  }
  
  .label-text {
      font-size: .85rem;
      background-color: transparent;
      color: var(--text-main);
      transform: translate(0);
      transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
  }
  
  .datepicker-field, .placeholder-text {
      font-size: .85rem;
      padding: 0 1rem;
  }
  
  .datepicker-field.active + .placeholder-text .label-text {
      font-size: .75rem;
      padding-inline: .2rem;
      color: var(--primary-color);
      transform: translate(0, -130%);
      background-color: var(--above-bg-db);
  }
  
  .popover {
      position: absolute;
      display: flex;
      justify-content: space-around;
      width: 16rem;
      background-color: var(--bg-main);
      padding: 1rem;
      border-radius: .5rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;
  }
  
  .popover-option {
      padding: .5rem;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .5rem;
      transition: background-color 0.3s, color 0.3s;
  }
  
  .popover-option.selected {
      background-color: var(--primary-color);
      color: #fff;
      font-weight: bold;
  }
  
  .popover-option:focus {
    outline: 1px solid var(--primary-color);
  }