[data-theme="light"] {
  --above-bg-db: var(--bg-secondary)
}
[data-theme="dark"] {
  --above-bg-db: var(--bg-secondary)
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.input-field {
    height: 2rem;
    width: 17rem;
    border: 1px solid var(--neutral-200);
    border-radius: .5rem;
    background: transparent;
    color: var(--text-main);
    position: relative;
  }
  .placeholderText{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* border: 3px solid transparent; */
    background-color: transparent;
    display: flex;
    align-items: center;
    pointer-events: none;
  }
  .labelText {
    font-size: .85rem;
    background-color: transparent;
    color: var(--text-main);
    transform: translate(0);
    transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
  }
  input, .placeholderText{
    font-size: .85rem;
    padding: 0 1rem;
  }
  .input-field:focus {
    border-color: transparent;
    outline: 2px solid var(--primary-color);
    color: var(--text-main);
  }
  input:focus + .placeholderText .labelText{
    font-size: .75rem;
    padding-inline: .2rem;
    color: var(--text-main);
    transform: translate(0, -130%);
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
  input:focus + .placeholderText .labelText, :not(input[value=""]) + .placeholderText .labelText{
    background-color: var(--above-bg-db);
    font-size: .75rem;
    padding-inline: .2rem;
    color: var(--text-tertiary);
    transform: translate(0, -130%);
  }
  
  input:focus + .placeholderText .labelText{
    border-color: var(--primary-color);
    color: var(--primary-color);
  }

  .visibility-toggle {
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
  }
  
  .visibility-toggle .material-symbols-outlined {
    font-size: 16px;
    color: var(--text-main);
  }