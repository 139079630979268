.profile-dropdown-container {
    width: fit-content;
    position: relative;
}
.profile-dropdown {
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    padding: .5rem;
    border-radius: .5rem;
    font-size: .9rem;
    position: relative;
    user-select: none;
}

.profile-dropdown:hover {
    background: var(--neutral-200);
}

.profile-pic {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 50%;
}

.profile-dropdown-menu {
    position: absolute;
    background-color: var(--bg-main);
    padding: .5rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    font-size: .9rem;
    z-index: 1000;
    width: 100%;
    min-width: 180px;
    right: 0;
    box-sizing: border-box;
}
.profile-dropdown-choices {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}
.profile-dropdown-choices > li {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: .5rem .25rem;
    cursor: pointer;
    border-radius: .4rem;
}
.profile-dropdown-choices > li:hover {
    background-color: var(--neutral-200);
}
.profile-dropdown-choices > li > button {
    color: var(--text-main);
}
.profile-dropdown-choices .material-symbols-outlined {
    font-size: 20px;
}